import { useState } from 'react';
import { get } from '../../../LocalStorage/LocalStorage';
import environment from '../../../Environments/Environment';

const useDeleteModel = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [statusCode, setStatusCode] = useState(null);

  const deleteModel = async (modelId) => {
    setLoading(true);
    try {
      const token = 'Token ' + get('token');
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: token },
        body: JSON.stringify({ model_id: modelId }),
      };

      const response = await fetch(`${environment.server_root}/api/avatar/delete_model/`, requestOptions);
      const result = await response.json();
      setStatusCode(response.status);

      if (!response.ok) {
        throw new Error(result.message || 'Error deleting model');
      }

      setData(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, statusCode, deleteModel };
};

export default useDeleteModel;
