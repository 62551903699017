import { useState } from 'react';
import { get, set } from '../../../LocalStorage/LocalStorage';
import environment from '../../../Environments/Environment'

const useUpdateProfileVisits = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const updateProfileVisits = async (updateInfo) => {
        if (!get('is_superuser')) {
            const token = 'Token ' + get('token');
            updateInfo['session_id'] = get('session_id');

            if (updateInfo.end_session) {
                set('session_id', null);
                set('start_session', null);
            }

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify(updateInfo),
            };

            setLoading(true);
            setError(null);

            try {
                const response = await fetch(`${environment.server_root}/api/profile/update_profile_visits/`, requestOptions);
                const result = await response.json();

                if (response.ok) {
                    setData(result);
                } else {
                    throw new Error(`Error: ${response.status}`);
                }

                result.status_code = response.status;
                return result;

            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
    };

    return { data, loading, error, updateProfileVisits };
};

export default useUpdateProfileVisits;
