import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { get, initializeLocalStorage, set } from './LocalStorage/LocalStorage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { lazy, Suspense } from 'react';
import importRetry from './importRetry';
import { logoutClear } from './LocalStorage/LocalStorage';
import './Styles/global.css'
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { CLIENT_ID } from './Screens/Payment/Config/Config';
import VrVideoPlay from './ClientProject/VrVideoProject/VrVideoPlay';
import ChallengeProfile from './Screens/SocialPlatformPages/Profiles/ChallengeProfile';
import Leaderboard from './Screens/Leaderboard/LeaderboardPage';
import { useProfileVisits, useUpdateProfileVisits } from './Servicies/CustomHook';

const MainScene = lazy(() => importRetry(() => import('./Screens/MainScene/MainScene')));
// const CreatePage = lazy(() => importRetry(() => import('./Screens/Landing/LandingPage')));
// const Stories = lazy(() => importRetry(() => import('./Screens/SocialPlatformPages/Stories')));
const Blogs = lazy(() => importRetry(() => import('./Screens/Blogs/Blogs')));
// const Feed = lazy(() => importRetry(() => import('./Screens/SocialPlatformPages/Feed')));
const CreatorProfile = lazy(() => importRetry(() => import('./Screens/SocialPlatformPages/Profiles/SelfProfile')));
const Profile = lazy(() => importRetry(() => import('./Screens/SocialPlatformPages/Profile')));
const ProfileHome = lazy(() => importRetry(() => import('./Screens/SocialPlatformPages/RouteComponents/ProfileHome')));
// const OthersProfile = lazy(() => importRetry(() => import('./Screens/SocialPlatformPages/Profiles/OthersProfiles')));
// const Publish = lazy(() => import('./Screens/PublishScreen/Publish'));
const Publish = lazy(() => importRetry(() => import('./Screens/PublishScreen/VirtualExperience')));
const VrWebsitePublish = lazy(() => importRetry(() => import('./Screens/PublishScreen/VRWebsite')));
const VrWebsitePublishCalendy = lazy(() => importRetry(() => import('./Screens/PublishScreen/VrWebsite/VrWebsiteCalendy')));
const VrWebsitePublishHubspot = lazy(() => importRetry(() => import('./Screens/PublishScreen/VrWebsite/VrWebsiteHubspot')));
const ProductModel = lazy(() => importRetry(() => import('./Screens/Popup3D/ProductModelOnHover')));
const MapModel = lazy(() => importRetry(() => import('./Screens/Popup3D/MapWorldOctree')));
const VirtualWorld = lazy(() => importRetry(() => import('./Screens/VirtualWorld/VirtualWorld')));
const VirtualWorldEditor = lazy(() => importRetry(() => import('./Screens/VirtualWorld/RouteComponents/VirtualWorldEditor')));
const ContactUs = lazy(() => importRetry(() => import('./Screens/ContactUs/ContactUs')));
const ContactUsCard = lazy(() => importRetry(() => import('./Screens/ContactUs/ContactUsCard')));
const Dashboard = lazy(() => importRetry(() => import('./Screens/Dashboard/Dashboard')));
const DashboardHome = lazy(() => importRetry(() => import('./Screens/Dashboard/RouteComponents/DashboardHome')));
const DashboardBlogs = lazy(() => importRetry(() => import('./Screens/Dashboard/RouteComponents/DashboardBlogs')));
const DashboardEmailCampaign = lazy(() => importRetry(() => import('./Screens/Dashboard/RouteComponents/DashboardEmailCampaign')));
const DashboardManage = lazy(() => importRetry(() => import('./Screens/Dashboard/RouteComponents/DashboardManage')));
const DashboardCategories = lazy(() => importRetry(() => import('./Screens/Dashboard/RouteComponents/DashboardCategories')));
const NewSignup = lazy(() => importRetry(() => import('./Screens/Signup&LoginPage/NewSignup')));
const LoginPage = lazy(() => importRetry(() => import('./Screens/Signup&LoginPage/LoginPage')));
const Scene = lazy(() => importRetry(() => import('./Screens/Scene')));
const Templates = lazy(() => importRetry(() => import('./Screens/VRTemplates/Templates')));

const HomePage = lazy(() => importRetry(() => import('./Screens/HomePage/HomePage')));
const StoriesPage = lazy(() => importRetry(() => import('./Screens/Stories/StoriesPage')))
const VrWebsite = lazy(() => importRetry(() => import('./Screens/VRWebsite/LandingPage')));
const Logout = lazy(() => importRetry(() => import('./Logout')));
const PageNotfound = lazy(() => importRetry(() => import('./Screens/PageNotFound/PageNotFound')));
// const ProfilePage = lazy(() => importRetry(() => import('./Screens/Profile/ProfilePage')));
const PlansPage = lazy(() => importRetry(() => import('./Screens/Payment/Components/PlansPage')));
const StoriesTemplates = lazy(() => importRetry(() => import('./Screens/Stories/RouteComponents/StoriesTemplates')));
const LoadApp = lazy(() => importRetry(() => import('./Screens/GruboxApp/LoadApp')));
const LoadVendingApp = lazy(() => importRetry(() => import('./Screens/GruboxApp/LoadVendingApp')));

const VrVideo = lazy(() => importRetry(() => import('./ClientProject/RouteComponents/VrVideo')));
const VrVideoHomePage = lazy(() => importRetry(() => import('./ClientProject/RouteComponents/HomePage')));
const VrVideoKevin = lazy(() => importRetry(() => import('./ClientProject/VrVideoProject/VrVideo')));

const DNARoutes = lazy(() => importRetry(() => import('./ClientProject/DNA_Project/DNARouteManger')));
const DNALandingPage = lazy(() => importRetry(() => import('./ClientProject/DNA_Project/LandingPage/LandingPage')));
const DNAAdmin = lazy(() => importRetry(() => import('./ClientProject/DNA_Project/AdiminPanel.jsx/AdminPanel')));
const DNALogin = lazy(() => importRetry(() => import('./ClientProject/DNA_Project/Login/Login')));

const App = () => {

  const { profileVisits } = useProfileVisits();
  const { updateProfileVisits } = useUpdateProfileVisits();

  useEffect(() => {
    initializeLocalStorage()
  }, [])

  useEffect(() => {
    const storedLoginTime = get('loginTime');
    if (storedLoginTime) {
      const logoutTimeout = 48 * 60 * 60 * 1000;   //12 hours in ms
      const timeDifferenceMs = Date.now() - parseInt(storedLoginTime, 10);  //Time diff in ms
      if (timeDifferenceMs > logoutTimeout) {
        updateProfileVisits({ "end_session": true }).then(result => {
          logoutClear()
          window.location.href = '/login';
        })
      }
    }
  }, [])


  const [idleFlag, setIdleFlag] = useState(false);
  useEffect(() => {
    const handleUserActivity = () => {
      lastInteractionTime = Date.now();
    };
    const checkIdleState = () => {
      const currentTime = Date.now();
      const idleTimeThreshold = 30 * 60 * 1000; // 30 min in milliseconds
      const isIdle = currentTime - lastInteractionTime > idleTimeThreshold;
      if (isIdle) {
        setIdleFlag(true);
        updateProfileVisits({ "end_session": true }).then(result => {
          if (result.success) {
            set('session_id', null)
          }
        });
      }
      else {
        if (idleFlag) {
          profileVisits()
        }
        setIdleFlag(false);
      }
    };
    let lastInteractionTime = Date.now();
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);
    const intervalId = setInterval(checkIdleState, 1 * 1000); //inactivity checked after 1 sec
    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div>
      <>
        <GoogleOAuthProvider clientId="451855764100-0qiomvvrsknpt3mlsjcjksc4hhurq78l.apps.googleusercontent.com">
          <PayPalScriptProvider options={{ "client-id": CLIENT_ID, currency: 'USD', vault: true, components: "buttons", intent: 'subscription' }}>
            <Suspense fallback={<>...</>}>
              <Router>
                <Routes>
                  {/* <Route path="/stories"
                    element={
                      <HomePage />
                    }
                  /> */}

                  <Route path="/" element={<StoriesPage />}>
                    <Route index element={<StoriesTemplates />} />
                    <Route path="virtual-showrooms" element={<StoriesTemplates />} />
                    <Route path="social-posts" element={<StoriesTemplates />} />
                    <Route path="3d-visualization" element={<StoriesTemplates />} />
                    <Route path="virtual-tours" element={<StoriesTemplates />} />
                    <Route path="website-builder" element={<StoriesTemplates />} />
                  </Route>
                  <Route path="/stories" element={<HomePage />}></Route>
                  <Route path="/leaderboard" element={<Leaderboard />} />
                  <Route path="/virtual-experience/:experience_id"
                    element={
                      <Publish />
                    }
                  />
                  <Route path="/virtual-world/"
                    element={
                      <VirtualWorld />
                    }
                  />
                  <Route path="/3dwebsites/:experience_id"
                    element={
                      <VrWebsitePublish />
                    }
                  />
                  <Route path="/3dwebsites/:experience_id/contact-us"
                    element={
                      <VrWebsitePublishHubspot />
                    }
                  />
                  <Route path="/3dwebsites/:experience_id/contactUs"
                    element={
                      <ContactUsCard />
                    }
                  />
                  <Route path="/3dwebsites/:experience_id/book-appointment"
                    element={
                      <VrWebsitePublishCalendy />
                    }
                  />
                  <Route exact path="/create"
                    element={
                      <MainScene />
                    }
                  />
                  <Route exact path="/create/:experience_id"
                    element={
                      <MainScene />
                    }
                  />
                  <Route exact path="/create-vrwebsite"
                    element={
                      <VrWebsite />
                    }
                  />
                  <Route exact path="/create-vrwebsite/:unique_id"
                    element={
                      <VrWebsite />
                    }
                  />
                  <Route path="/login"
                    element={
                      <LoginPage />
                    }
                  />
                  {/*------------ Dashboard Routes ---------- */}
                  <Route path="dashboard" element={<Dashboard />}>
                    <Route index element={<DashboardHome />} />
                    <Route path="manage" element={<DashboardManage />} />
                    <Route path="blogs" element={<DashboardBlogs />} />
                    <Route path="email-campaign" element={<DashboardEmailCampaign />} />
                    <Route path="categories" element={<DashboardCategories />} />
                  </Route>
                  <Route exact path="/blog" element={<Blogs />} />
                  <Route exact path="/blog/:unique_id" element={<Blogs />} />
                  <Route exact path="/logout" element={<Logout />} />
                  {/* <Route index element={
                    <HomePage />
                    // <CreatePage />
                  }
                  /> */}
                  <Route path="/scene2" element={<Scene />} />
                  <Route path="/signup" element={<NewSignup />} />
                  <Route exact path="/gruboxApp" element={<LoadApp />} />
                  <Route exact path="/gruboxVendingApp" element={<LoadVendingApp />} />

                  {/*------------ Profile Routes ---------- */}
                  <Route path="/profile" element={<Profile />}>
                    <Route index element={<CreatorProfile />} />
                    <Route exact path=":profile_id" element={<CreatorProfile />} />
                    <Route path='dashboard' element={<ProfileHome />} />
                    <Route path="billing" element={<CreatorProfile />} />
                    <Route path="challenges" element={<ChallengeProfile />} />
                  </Route>
                  <Route path="/product" element={<ProductModel />} />
                  <Route path="/map" element={<MapModel />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/templates" element={<Templates />} />
                  <Route path="/pricing" element={<PlansPage />} />


                  {/*----------------- Project Routes--------------- */}
                  <Route path="/vr-video" element={<VrVideo />}>
                    <Route index element={<VrVideoHomePage />} />
                    {/* <Route exact path=":experience_id" element={<CreatorProfile />} /> */}
                    <Route exact path=":experience_id" element={<VrVideoPlay />} />
                    <Route path='kevin46' element={<VrVideoKevin />} />
                  </Route>
                  <Route path="/vr-world" element={<VirtualWorld />}>
                    <Route index element={<VirtualWorldEditor />} />
                    {/* <Route exact path=":experience_id" element={<CreatorProfile />} /> */}
                    <Route exact path=":experience_id" element={<VrVideoPlay />} />
                    <Route path='kevin46' element={<VrVideoKevin />} />
                  </Route>

                  <Route path="/dna" element={<DNARoutes />}>
                    <Route index element={<DNALandingPage />} />
                    <Route exact path="admin" element={<DNAAdmin />} />
                    <Route exact path="login" element={<DNALogin />} />
                  </Route>
                  <Route path='*'
                    element={
                      <PageNotfound />
                    }
                  />
                </Routes>
              </Router>
            </Suspense>
          </PayPalScriptProvider>
        </GoogleOAuthProvider>
      </>
    </div>
  )
}

export default App