import { useState, useEffect } from 'react';
import { get } from '../../../LocalStorage/LocalStorage';
import environment from '../../../Environments/Environment';

const useUploadedModel = (initialPage, category_id) => {
  const [data, setData] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(0);

  const fetchUploadedModel = async (pageToFetch) => {
    try {
      setLoading(true);
      const token = 'Token ' + get('token');
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: token },
      };

      let url = `${environment.server_root}/api/avatar/get_models?upload=true`;

      if (category_id) {
        url += `&category_id=${category_id}`;
      }
      if (pageToFetch) {
        url += `&page=${pageToFetch}`;
      }

      const response = await fetch(url, requestOptions);
      const result = await response.json();
      result.status_code = response.status;

      // Accumulate models if data already exists
      setData(prevData => [...prevData, ...result.data]); // Accumulate models
      setTotalPages(result.total_pages || 0); // Assuming `total_pages` is in the response
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUploadedModel(page);
  }, [page, category_id]);

  const goToNextPage = () => {
    if (page < totalPages) setPage(prevPage => prevPage + 1);
  };

  const goToPreviousPage = () => {
    if (page > 1) setPage(prevPage => prevPage - 1);
  };

  const refreshPage = () => {
    setPage(initialPage); // Reset to initial page
    setData([]); // Clear accumulated models
    fetchUploadedModel(initialPage); // Fetch models for the initial page
  };

  return { data, loading, error, page, totalPages, goToNextPage, goToPreviousPage, refreshPage };
};

export default useUploadedModel;