import { useState } from 'react';
import axios from 'axios';
import { get } from '../../../LocalStorage/LocalStorage';
import environment from '../../../Environments/Environment';

const useNewUploadModel = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [statusCode, setStatusCode] = useState(null);

    const uploadModels = async (formData) => {
        setLoading(true);
        try {
            const token = 'Token ' + get('token');
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: token,
                },
            };

            const url = `${environment.server_root}/api/avatar/upload_models/`;
            const response = await axios.post(url, formData, config);
            setStatusCode(response.status);

            if (response.status !== 200) {
                throw new Error(response.data.message || 'Error uploading models');
            }

            setData(response.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    return { data, loading, error, statusCode, uploadModels };
};

export default useNewUploadModel;