import { useState } from 'react';
import { get, set } from '../../../LocalStorage/LocalStorage';
import environment from '../../../Environments/Environment'

const useProfileVisits = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const profileVisits = async () => {
        if (!get('is_superuser')) {
            const token = 'Token ' + get('token');
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({}),
            };

            setLoading(true);
            setError(null);

            try {
                const response = await fetch(`${environment.server_root}/api/profile/profile_visits/`, requestOptions);
                const result = await response.json();

                if (response.ok) {
                    set('session_id', result.session_id);
                    set('start_session', result.start_session);
                    setData(result);
                } else {
                    throw new Error(`Error: ${response.status}`);
                }

                result.status_code = response.status;
                return result;

            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
    };

    return { data, loading, error, profileVisits };
};

export default useProfileVisits;
