import { useState, useEffect } from 'react';
import { get } from '../../../LocalStorage/LocalStorage'; 
import environment from '../../../Environments/Environment';

const useModelCategories = (category = null) => {
  const [groupedCategories, setGroupedCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      const token = 'Token ' + get('token');
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: token },
      };

      let url = `${environment.server_root}/api/avatar/get_avatar_categories_grouped/`;
      if (category) {
        url += `?category=${category}`;
      }

      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();

        if (response.ok) {
          console.log(data?.categories_info)
          setGroupedCategories(data?.categories_info || []);
        } else {
          throw new Error(data.detail || 'Error fetching categories');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [category]);

  return { groupedCategories, loading, error };
};

export default useModelCategories;
